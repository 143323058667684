/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

.link-title-box {
    background-color: #FFFFFF;
    height: 50%;
    width: 80%;
    position: absolute;
    top: 25%;
    left: 10%;
}

.page-after-post-cats {
    padding-left: 25px;
    margin-top: 20px;
}

.link-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #333333;
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 1.3em;
}

.share-button:hover i {
    color: #444444;
    cursor: pointer;
}

.navbar {
    padding: 0.75rem 1rem 0.75rem 1rem;
}

.link-box {
    max-height: 120px;
    overflow: hidden;
}

.link-box:hover .link-title-box {
    background-color: rgba(240, 240, 240, 1);
}

.link-box:hover .link-title {
    color: #000000;
}

.navbar-brand {
	padding-top: 0em;
    padding-bottom: 0em;
}


.mediumnavigation
.navbar-brand img {
	max-height: 2em;
	margin-right: 15px;
}

.mediumnavigation .nav-item, .dropdown-menu {
	font-size: 0.9em;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.slant-begin {
	-webkit-transform: skewY(2deg);
    -moz-transform: skewY(2deg);
    -ms-transform: skewY(2deg);
    -o-transform: skewY(2deg);
    transform: skewY(2deg);
	min-height: 10em;
	background: darkgray;
}

.slant-end {
	-webkit-transform: skewY(-2deg);
    -moz-transform: skewY(-2deg);
    -ms-transform: skewY(-2deg);
    -o-transform: skewY(-2deg);
    transform: skewY(-2deg);
}

.card-category {
	color: blue;
	font-size: 0.8em;
}


.card-footer {
    background-color: unset;
}
